import React from 'react'
import Layout from '../../layouts'
import PageWrapper from '../../components/PageWrapper'
import MetaHeader from '../../components/MetaHeader'

const Success = () => (
  <Layout>
    <MetaHeader title="Sign-up successful" hidden />
    <PageWrapper>
      <section>
        <h1>Sign-up successful</h1>
        <p>
          Thank you for signing up for updates on the Future Capital Executive
          Program
        </p>
      </section>
    </PageWrapper>
  </Layout>
)

export default Success
